
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import DriverSet from '@/components/DriverSet.vue'
import {IWebConfigItem, IWebConfigUpdateItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";
import ImageView from "@/components/ImageView.vue";
import UploadComponent from "@/components/UploadComponent.vue";

interface IState {
  list: IWebConfigItem[],
  loading: boolean
  editItem: IWebConfigUpdateItem
  visible: boolean
  type: boolean // true:新增; false: 编辑
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '配置项',
    dataIndex: 'tag',
    key: 'tag',
  },
  {
    title: '内容',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
    key: 'update_time',
  },
  {
    title: '操作',
    key: 'action',
  },
]

export default defineComponent({
  name: 'WebConfig',
  components: {UploadComponent, ImageView, DriverSet},
  setup() {
    const updateComponentRef = ref()
    const state: IState = reactive({
      list: [],
      loading: false,
      visible: false,
      type: false,
      editItem: {
        id: undefined,
        tag: '',
        content: '',
        is_json: false
      },
    })


    //获取列表
    const getList = async () => {
      state.loading = true
      const res = await ApiLogistics.getWebsiteInformationList()
      state.loading = false
      if (res && res.error_code === 0) {
        state.list = res.data?.list || []
      }
    }

    const setEditItem = (item: IWebConfigItem | undefined = undefined, visible: boolean, type: boolean = false) => {
      state.visible = visible
      state.type = type
      if (visible && !item) {
        // 新增
        state.editItem = {
          id: undefined,
          tag: '',
          content: '',
          is_json: false
        }
        return
      }
      // 编辑
      if (item) {
        const config = {
          id: item.id,
          tag: item.tag,
          content: item.content,
          is_json: item.is_json,
        }
        state.editItem = {...config}
      }
    }

    const onImageChange = (url: string) => state.editItem.content = url

    // 新增编辑
    const onEdit = async () => {
      if (!state.editItem) {
        return
      }
      state.loading = true
      const res = await ApiLogistics.setWebsiteInformation({...state.editItem})
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        setEditItem(undefined, false);
        await getList()
      } else {
        message.error(res?.msg || '操作失败')
      }
    }

    // 删除
    const onDel = async () => {
      // delWebsiteInformation
    }

    // 去详情
    const goDetail = (item: IWebConfigItem) => {
      router.push({path: '/feedback/detail', query: {id: item.id}}).then()
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      ...toRefs(state),
      getList,
      dateFormat,
      goDetail,
      setEditItem,
      onImageChange,
      onEdit,
    }
  }
})
